
























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { DecoContestVotableEntry } from '@/game/infos/dataServerPackets';
import userx from '@/store/modules/userx';
import fb from '@/store/sf-firestore';
import DecoEntryForVote from './DecoEntryForVote.vue';
import globalx from '@/store/modules/globalx';
import { eventTimes, TimeLimitedQuestCode } from '@/game/infos/questTimeLimited';
import { ordinalSuffix } from '@/util/number';

@Component({
	components: {
		DecoEntryForVote,
	},
})
export default class DecoVote extends Vue {
	public get contestOrdinal() {
		return globalx.decoContestOrdinal;
	}
	public get votingPeriod() {
		const d1 = new Date(eventTimes[TimeLimitedQuestCode.DecoVote][0]);
		const d2 = new Date(eventTimes[TimeLimitedQuestCode.DecoVote][1]);
		const str = `${d1.getDate()} ~ ${new Intl.DateTimeFormat(
			'en-UK', {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
			hour12: true,
			hour: '2-digit',
			minute: '2-digit',
		}).format(d2)}`;

		return str;
	}

	public get decoStage() {
		const result = globalx.autoDecoContestStage;
		if (result === this._decoStage) {
			return result;
		}
		this._decoStage = result;
		if (result === 'vote') {
			this.loadEntries();
			const el = (this.$refs.entryContainer as HTMLDivElement);
			if (el) {
				this.entryContainerWidth = el.clientWidth;
			}
		}
		return result;
	}
	public get numEntries() {
		return this.entryContainerWidth >= 1582
			? 10
			: this.entryContainerWidth >= 1266
				? 8
				: this.entryContainerWidth >= 949
					? 6
					: 4;
	}
	public get culledEntries() {
		return this.entries.slice(0, this.numEntries);
	}
	public entries: DecoContestVotableEntry[] = Array.from({ length: 10 }).fill({
		uid: '',
		decoSet: '',
		skin: null,
		fishType: 0,
		numLikes: 0,
		numDislikes: 0,
		liked: false,
		disliked: false,
		reported: false,
	}) as DecoContestVotableEntry[];

	public entryContainerWidth = 1600;

	private _decoStage = '';
	public mounted() {
		window.addEventListener('resize', this.onResize);
	}
	public beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	}
	public onResize() {
		this.entryContainerWidth = (this.$refs
			.entryContainer as HTMLDivElement).clientWidth;
	}

	public async loadEntries() {
		this.entries = await fb.getDecoEntriesForVote(globalx.decoContest, this.numEntries);
	}
}
